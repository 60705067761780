import * as React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { InfoState, clearInfo } from "../../store/infoSlice";

const InfoInfoContainer = styled.div<{
  display: InfoState["display"];
  type: InfoState["type"];
}>`
  display: ${(props) => (props.display ? "flex" : "none")};
  color: ${(props) => {
    switch (props.type) {
      case "success":
        return "#3CB371";
      case "error":
        return "#FF6347";
      default:
        return "#fff";
    }
  }};
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  :hover {
    cursor: pointer;
  }
`;

const InfoComponent = () => {
  const InfoItem = useAppSelector((state) => state.infoSlice);
  const dispatch = useAppDispatch();

  const { display, message, type } = InfoItem;

  const onclickHandler = () => {
    dispatch(clearInfo());
  };

  return (
    <InfoInfoContainer display={display} type={type} onClick={onclickHandler}>
      <span>{message}</span>
    </InfoInfoContainer>
  );
};

export default InfoComponent;
