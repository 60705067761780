import * as React from "react";

interface ITextProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Text: React.FC<ITextProps> = (props) => {
  return (
    <input
      {...props}
      // type="text"
      // onKeyDown={onKeyDown}
      // onChange={(e) => setMessage(e.target.value)}
      // value={message}
      // placeholder="Enter a message"
      style={{
        width: "80%",
        height: "2rem",
        backgroundColor: "rgba(255,255,255,0.05)",
        borderColor: "transparent",
        color: "rgba(255,255,255,0.8)",
        fontSize: "1rem",
        fontFamily: "inherit",
        ...props.style,
      }}
    />
  );
};

export default Text;
