import { useState, useEffect } from "react";

import retrieveMessages from "../../../api/admin/retrieveMessages";
import { AdminMessageItem } from "../../../interfaces";
import Messages from "./MessageItem";
import Button from "../../../Components/Basic/Button";

interface MessageHistoryProps {
  token: string;
}

const MessageHistory: React.FC<MessageHistoryProps> = ({ token }) => {
  const messagesPerPage = 50;
  const [messages, setMessages] = useState<AdminMessageItem[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [ip, setIp] = useState<string | null>(null);
  const [connection, setConnection] = useState<string | null>(null);

  useEffect(() => {
    loadMessages();
  }, [ip, connection]);

  const clickId = (connectionValue: string) => {
    // Don't do anything if they are already the same
    if (connection === connectionValue) {
      return;
    }

    reset();
    setConnection(connectionValue);
  };

  const clickIp = (ipValue: string) => {
    // Don't do anything if they are already the same
    if (ip === ipValue) {
      return;
    }
    reset();
    setIp(ipValue);
  };

  const reset = () => {
    setError(null);
    setMessages([]);
    setIp(null);
    setConnection(null);
    setPage(0);
    setLoading(false);
  };

  const loadMessages = async () => {
    setLoading(true);
    setError(null);

    try {
      const { messages: newMessages, count } = await retrieveMessages({
        token,
        connectionId: connection || undefined,
        connectionIp: ip || undefined,
        after: page * messagesPerPage,
      });

      setCount(count);
      setMessages((prevMessages: AdminMessageItem[]) => [
        ...prevMessages,
        ...newMessages,
      ]);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMessages();
  }, [page]);

  let Title = `Message History - All Messages (${count} total)`;
  if (ip) {
    Title = `Messages for IP: ${ip} (${count} total)`;
  } else if (connection) {
    Title = `Messages for Connection: ${connection} (${count} total)`;
  }

  return (
    <div>
      <h2>{Title}</h2>
      {(ip || connection) && <Button onClick={reset}>Clear Filter</Button>}
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      <Messages messages={messages} setIp={clickIp} setId={clickId} />

      <button onClick={() => setPage((prevPage: number) => prevPage + 1)}>
        Load More
      </button>
    </div>
  );
};

export default MessageHistory;
