const colours = [
  "red",
  "blue",
  "green",
  "yellow",
  "orange",
  "purple",
  "pink",
  "brown",
  "black",
  "white",
  "grey",
  "cyan",
  "lime",
  "maroon",
  "rose",
];

const ColourPicker: React.FC<{
  colour: string;
  setColour: (colour: string) => void;
}> = ({ colour, setColour }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h2> Select Colour</h2>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {colours.map((colourOption) => {
          return (
            <div
              key={colourOption}
              style={{
                // animate movement
                transition: "0.25s",
                width: "2rem",
                height: "2rem",
                backgroundColor: colourOption,
                margin: "0 0.5rem 0 0.5rem",
                marginTop: colourOption === colour ? "1rem" : undefined,
                boxShadow:
                  colourOption === colour
                    ? "0px 0px 10px 5px rgba(255,255,255,0.5)"
                    : undefined,
                border:
                  colourOption === colour
                    ? "2px solid white"
                    : "2px solid black",
              }}
              onClick={() => setColour(colourOption)}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default ColourPicker;
