import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Message } from "../interfaces";

interface MessagesState {
  messages: Message[];
}

const initialState: MessagesState = {
  messages: [],
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
    clearMessages: (state) => {
      state.messages = [];
    },
  },
});

export const { addMessage, clearMessages } = messagesSlice.actions;

export const selectMessages = (state: { messages: MessagesState }) => {
  return state.messages.messages;
};

export default messagesSlice.reducer;
