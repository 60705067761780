import * as React from "react";

interface IToggleProps {
  text: string;
  value: boolean;
  toggle: () => void;
  style?: React.HTMLAttributes<HTMLDivElement>[`style`];
}

const Toggle: React.FC<IToggleProps> = ({ text, toggle, value, style }) => {
  return (
    <div
      style={{ cursor: "pointer", display: "flex", ...style }}
      onClick={toggle}
    >
      <div style={{ marginTop: "auto", marginBottom: "auto" }}>
        <input type="checkbox" checked={value} style={{ cursor: "pointer" }} />
        <span
          style={{
            fontSize: "0.75rem",
            alignContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {text}
        </span>
      </div>
    </div>
  );
};

export default Toggle;
