import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InfoInput {
  message: string;
}

export interface InfoState {
  message: string;
  type: "error" | "information" | "success";
  display: boolean;
}

const initialState: InfoState = {
  message: "",
  type: "error",
  display: false,
};

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<InfoInput>) => {
      state.message = action.payload.message;
      state.type = "error";
      state.display = true;
    },
    setInfo: (state, action: PayloadAction<InfoInput>) => {
      state.message = action.payload.message;
      state.type = "information";
      state.display = true;
    },
    setSuccess: (state, action: PayloadAction<InfoInput>) => {
      state.message = action.payload.message;
      state.type = "success";
      state.display = true;
    },
    clearInfo: (state) => {
      state.message = "";
      state.type = "error";
      state.display = false;
    },
  },
});

export const { setError, setInfo, setSuccess, clearInfo } = infoSlice.actions;

export const retrieveInfo = (state: InfoState) => {
  return state;
};

export default infoSlice.reducer;
