import * as React from "react";
import styled from "styled-components";

import { AdminMessageItem } from "../../../interfaces";
import Toggle from "../../../Components/Basic/Toggle";
import Button from "../../../Components/Basic/Button";

const MessageItemWrapper = styled.div<{ isHeader?: boolean; isOdd?: boolean }>`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 12px 0;
  background-color: ${(props) =>
    props.isOdd ? "rgba(0, 0, 0, 0.25)" : "unset"};
`;

const ID = styled.div`
  width: 12%;
`;
const IP = styled.div`
  width: 12%;
`;
const Name = styled.div`
  width: 12%;
`;
const Timestamp = styled.div`
  width: 15%;
`;
const Message = styled.div`
  text-align: left;
  width: 50%;
`;

const MessageItemHeader: React.FC<{ showIp?: boolean; showId?: Boolean }> = ({
  showId,
  showIp,
}) => {
  return (
    <MessageItemWrapper isOdd>
      {showId && <ID>Connection ID</ID>}
      {showIp && <IP>Connection IP</IP>}
      <Name>Connection Name</Name>
      <Timestamp>Timestamp</Timestamp>
      <Message>Message</Message>
    </MessageItemWrapper>
  );
};

const MessageItem: React.FC<{
  message: AdminMessageItem;
  isOdd: boolean;
  showId?: boolean;
  showIp?: boolean;
  setIp: (ip: string) => void;
  setId: (id: string) => void;
}> = ({ message, isOdd, showId, showIp, setId, setIp }) => {
  return (
    <MessageItemWrapper isOdd={isOdd}>
      {showId && (
        <ID>
          <Button onClick={() => setId(message.connection.id)}>
            {message.connection.id}{" "}
          </Button>
        </ID>
      )}
      {showIp && (
        <IP>
          <Button onClick={() => setIp(message.connection.ip)}>
            {message.connection.ip}{" "}
          </Button>
        </IP>
      )}
      <Name>{message.connection.name}</Name>
      <Timestamp>{message.timestamp}</Timestamp>
      <Message>{message.message}</Message>
    </MessageItemWrapper>
  );
};

const Messages: React.FC<{
  messages: AdminMessageItem[];
  setIp: (ip: string) => void;
  setId: (id: string) => void;
}> = ({ messages, setId, setIp }) => {
  const [showId, setShowId] = React.useState<boolean>(false);
  const [showIp, setShowIp] = React.useState<boolean>(false);

  const toggleId = () => {
    setShowId((prev: boolean) => !prev);
  };
  const toggleIp = () => {
    setShowIp((prev: boolean) => !prev);
  };

  return (
    <div>
      <Toggle text="Show IP" value={showIp} toggle={toggleIp} />
      <Toggle text="Show ID" value={showId} toggle={toggleId} />
      <MessageItemHeader showId={showId} showIp={showIp} />

      {messages.map((message: AdminMessageItem, index: number) => (
        <MessageItem
          showId={showId}
          showIp={showIp}
          setId={setId}
          setIp={setIp}
          message={message}
          isOdd={index % 2 === 1}
        />
      ))}
    </div>
  );
};

export default Messages;
