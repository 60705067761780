import * as React from "react";
import styled from "styled-components";
import * as constants from "../../constants";

const { mobileWidth } = constants.default.breakPoints;

const StyledDiv = styled.div`
  /* If width is mobile, set width to 100vw */
  width: 75vw;
  @media (max-width: ${mobileWidth}px) {
    width: 100vw;
  }
  min-height: 100vh;
`;

interface AppWrapperProps {
  children: React.ReactNode;
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};

export default AppWrapper;
