import axios from "axios";
import { User } from "../../interfaces";
import configuration from "../../configuration";

const retrievePlayers = async (): Promise<User[]> => {
  try {
    // TODO axios base setup somewhere...
    // also tidy this shit up
    const { API_ENDPOINT } = configuration;

    const url = `${API_ENDPOINT}listPlayers`;

    const response = await axios.get(url);

    const { data } = response;

    // TODO output validation....
    return data;
  } catch (e) {
    console.log(e);
  }

  return [];
};

export default retrievePlayers;
