import * as React from "react";
import Input from "./Components/Input";
import Messages from "./Components/Messages";
import Wrapper from "./Components/Wrapper";
import Players from "./Components/Players";

interface ChatProps {
  disconnect: () => void;
}

const Chat: React.FC<ChatProps> = ({ disconnect }) => {
  const [autoScroll, setAutoScroll] = React.useState<boolean>(true);
  const [playersVisible, setPlayersVisible] = React.useState<boolean>(false);

  const togglePlayers = () => setPlayersVisible(!playersVisible);
  const toggleAutoScroll = () => setAutoScroll(!autoScroll);

  return (
    <Wrapper disconnect={disconnect} togglePlayers={togglePlayers}>
      <Messages settings={{ autoScroll }} />
      {/* <Players playersVisible={playersVisible} /> */}
      <Input autoScroll={autoScroll} toggleAutoScroll={toggleAutoScroll} />
    </Wrapper>
  );
};

export default Chat;
