import React from "react";

import { addMessage, clearMessages } from "./store/messageSlice";
import { addPlayer, removePlayer, setPlayers } from "./store/playersSlice";

import { wsClient, connectSocket } from "./socket";
import "./App.css";
import Connect from "./Pages/Connect";
import Chat from "./Pages/Chat";
import { Message, MessageType, User } from "./interfaces";
import { useAppDispatch } from "./hooks";
import retrievePlayers from "./api/retrievePlayers";
import sendSocketMessage from "./socket/sendSocketMessage";
import { setLocalStorage } from "./localStorageConfig";
import { clearInfo, setError } from "./store/infoSlice";

function App(): React.ReactElement {
  const dispatch = useAppDispatch();

  const [isConnected, setConnected] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [pingInterval, setPingInterval] = React.useState<any>(null);

  // Call ping every minute
  // TODO: better logic? Some greater disconnect handling somewhere...
  React.useEffect(() => {
    const interval = setInterval(() => {
      sendSocketMessage({ type: "ping", data: "hi" });
    }, 60000);

    setPingInterval(interval);

    return () => clearInterval(interval);
  }, []);

  const onMessage = (message: any) => {
    receiveMessage(message.data as unknown as string);
  };

  const connect = async (username: string, colour: string) => {
    setLoading(true);
    await connectSocket({ username, colour }, onMessage);
    setConnected(true);

    setLocalStorage("username", username ?? "");
    setLocalStorage("colour", colour ?? "white");

    const players = await retrievePlayers();

    dispatch(setPlayers(players));

    setLoading(false);
  };

  const disconnect = () => {
    if (wsClient) wsClient.close();

    clearInterval(pingInterval);
    setConnected(false);
    dispatch(clearMessages());
    dispatch(clearInfo());
  };

  // TODO fuck this off somewhere else...
  const receiveMessage = (response: string) => {
    const messagePayload = JSON.parse(response) as MessageType;

    const { data, type } = messagePayload;

    // TODO switch statement this bitch...
    if (type === "ping") {
      sendSocketMessage({ type: "pong", data: "hi" });
      return;
    }

    if (type === "kickPlayer") {
      const systemMessage: Message = {
        message: data as string,
        user: {
          id: "system",
          name: "System",
          isSystem: true,
        },
      };

      dispatch(setError({ message: "You have been disconnected" }));
      dispatch(addMessage(systemMessage));
    }

    if (type === "message") {
      // If type is message, data is a Message
      const message = data as Message;

      dispatch(addMessage(message));
      return;
    }

    // TODO tidy all this shit up
    if (type === "playerConnect") {
      // If type is playerConnect, data is a User
      const user = data as User;

      const systemMessage: Message = {
        message: `${user.name} has connected`,
        user: {
          id: "system",
          name: "System",
          isSystem: true,
        },
      };

      dispatch(addMessage(systemMessage));
      dispatch(addPlayer(user));
    }

    if (type === "playerDisconnect") {
      // If type is playerConnect, data is a User
      const user = data as User;

      const systemMessage: Message = {
        message: `${user.name} has disconnected`,
        user: {
          id: "system",
          name: "System",
          isSystem: true,
        },
      };

      dispatch(addMessage(systemMessage));
      dispatch(removePlayer(user.id));
    }
  };

  if (isLoading) {
    return (
      <div className="App">
        <header className="App-header">
          <div>
            <h1> Connecting... </h1>
          </div>
        </header>
      </div>
    );
  }

  return (
    <>
      {!isConnected && <Connect connect={connect} />}
      {isConnected && <Chat disconnect={disconnect} />}
    </>
  );
}

export default App;
