import * as React from "react";
import Authenticate from "./authenticate";
import MessageHistory from "./messageHistory";

enum pages {
  Messages = "Messages",
}

const Admin: React.FC = () => {
  const [token, setToken] = React.useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);

  const authenticate = (tokenValue: string) => {
    setToken(tokenValue);
    setIsAuthenticated(true);
  };

  if (!isAuthenticated) {
    return <Authenticate authFn={authenticate} />;
  }

  return (
    <div>
      <MessageHistory token={token!} />
    </div>
  );
};

export default Admin;
