import { configureStore } from "@reduxjs/toolkit";

import messageSlice from "./messageSlice";
import playersSlice from "./playersSlice";
import infoSlice from "./infoSlice";

const store = configureStore({
  reducer: {
    messageSlice,
    playersSlice,
    infoSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
