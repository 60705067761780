import * as React from "react";
import Text from "../../Components/Basic/Text";
import Button from "../../Components/Basic/Button";
import { getLocalStorage } from "../../localStorageConfig";
import ColourPicker from "./Colour";
import Changelog from "./Changelog";

interface ConnectProps {
  connect: (name: string, colour: string) => Promise<void>;
}

// TODO: Use Styled Components
// TODO enter to submit
const Connect: React.FC<ConnectProps> = ({ connect }) => {
  const [name, setName] = React.useState<string>("");
  const [colour, setColour] = React.useState<string>("");

  // set name on first render
  React.useEffect(() => {
    const username = getLocalStorage("username");
    if (username) setName(username);

    const colour = getLocalStorage("colour");
    if (colour) setColour(colour);
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    (async () => {
      if (event.key === "Enter") {
        if (name.length > 0) {
          await connect(name, colour);
        }
      }
    })();
  };

  const handleConnect = () => {
    (async () => {
      if (name.length > 0) {
        await connect(name, colour);
      }
    })();
  };

  const connectButtonEnabled = () => {
    return name.length > 0;
  };

  return (
    <div>
      <h2> Connect </h2>
      <Text
        type="text"
        onKeyDown={(event) => handleKeyPress(event)}
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder="Enter a name"
      />

      <div>
        <ColourPicker colour={colour} setColour={setColour} />
      </div>

      <div style={{ margin: "2rem" }}>
        {connectButtonEnabled() && (
          <Button onClick={handleConnect}>Connect</Button>
        )}
      </div>

      <Changelog />
    </div>
  );
};

export default Connect;
