import axios from "axios";
import configuration from "../../configuration";
import { AdminMessageItem } from "../../interfaces";

const retrieveMessages = async (input: {
  token: string;
  connectionId?: string;
  connectionIp?: string;
  after: number;
}): Promise<{ messages: AdminMessageItem[]; count: number }> => {
  try {
    const { after, token, connectionId, connectionIp } = input;
    // TODO axios base setup somewhere...
    // also tidy this shit up
    const { API_ENDPOINT } = configuration;

    const url = `${API_ENDPOINT}/admin/messages`;
    const params = { after, connectionId, connectionIp };
    const headers = { Authorization: token };

    const response = await axios.get(url, { params, headers });

    const { data } = response;

    // TODO output validation....
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e.response?.data?.error ?? "No error provided";

      throw new Error(error);
    }

    throw new Error("Unable to retrieve messages: Unknown error");
  }
};

export default retrieveMessages;
