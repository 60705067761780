import * as React from "react";
import styled, { keyframes } from "styled-components";

const ChangelogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ChangelogHeader = styled.h2`
  margin: 0;
  padding: 0;
`;

const rotate = keyframes`
  0%, 100% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-2px);
  }
`;

const AnimatedGradientText = styled.span<{ delay?: string; isSpace?: boolean }>`
  animation: ${rotate} 1s linear infinite;
  animation-delay: ${(props) => props.delay};
  padding-left: ${(props) => (props.isSpace ? "0.15rem" : "0")};
  padding-right: ${(props) => (props.isSpace ? "0.15rem" : "0")};
`;

const SpecialAnimatedText: any = ({ children }: any) => {
  return (
    <span style={{ display: "inline-flex", padding: "1rem" }}>
      {children.split("").map((char: string, index: number) => (
        <AnimatedGradientText delay={`${index * 0.05}s`} isSpace={char === " "}>
          {char}
        </AnimatedGradientText>
      ))}
    </span>
  );
};

const Changelog: React.FC = () => {
  return (
    <ChangelogContainer>
      <ChangelogHeader> Changelog </ChangelogHeader>
      <SpecialAnimatedText> 2023-08-08: </SpecialAnimatedText>
      <span>Added a changelog page.</span>
      <span>Fixed Disconnection issue.</span>

      <SpecialAnimatedText> 2023-09-25: </SpecialAnimatedText>
      <span>Made things a little more responsive</span>
      <span>Added basic info panel</span>
    </ChangelogContainer>
  );
};

export default Changelog;
