import * as React from "react";
import styled from "styled-components";

import Button from "../../../../Components/Basic/Button";
import InfoPopup from "../../../../Components/InfoPopup";

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

interface IWrapperProps {
  togglePlayers: () => void;
  disconnect: () => void;
  children: React.ReactNode;
}

const Wrapper: React.FC<IWrapperProps> = ({
  disconnect,
  togglePlayers,
  children,
}) => {
  return (
    <StyledWrapper>
      <div
        style={{
          display: "flex",
        }}
      >
        <InfoPopup />
        <Button onClick={disconnect}>Disconnect</Button>
      </div>
      {children}
    </StyledWrapper>
  );
};

export default Wrapper;
