import ws, { WebSocket } from "isomorphic-ws";
import configuration from "../configuration";

export let wsClient: WebSocket;

interface ConnectionSocketInput {
  username: string;
  colour: string;
}

export const connectSocket = (
  { username, colour }: ConnectionSocketInput,
  onMessage: (message: any) => any
) => {
  return new Promise((resolve, reject) => {
    const endpoint = configuration.SOCKET_ENDPOINT;

    wsClient = new ws(endpoint);

    wsClient.onopen = () => {
      const authMessage = JSON.stringify({
        type: "auth",
        data: { username, colour },
      });

      wsClient.send(authMessage);

      resolve(true);
    };

    wsClient.onmessage = (message) => onMessage(message);
  });
};
