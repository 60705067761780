import { MessageType } from "../interfaces";
import store from "../store";

import { wsClient } from ".";
import { setError } from "../store/infoSlice";

const sendSocketMessage = ({ data, type }: MessageType) => {
  if (!wsClient || wsClient.readyState !== wsClient.OPEN) {
    store.dispatch(setError({ message: "Disconnected." }));

    return;
  }

  if (!data || !type) {
    throw new Error("Missing data or type");
  }

  const payload = JSON.stringify({
    data,
    type,
  });

  wsClient.send(payload);
};

export default sendSocketMessage;
