import { Message } from "../../../../interfaces";

interface MessageProps extends Message {
  newUserMessage?: boolean;
}

const MessageComponent: React.FC<MessageProps> = ({
  user,
  message,
  newUserMessage,
}) => {
  const { name, isSystem, colour } = user;

  const additionalStyle = newUserMessage ? { marginBottom: "1rem" } : {};

  return (
    <div style={{ display: "flex", border: "1px", ...additionalStyle }}>
      <div
        style={{
          // width: "25%",
          textAlign: "left",
          color: isSystem ? "red" : colour ?? "white",
        }}
      >
        {name}: &nbsp;
      </div>
      <div
        style={{
          // width: "75%",
          textAlign: "left",
          color: isSystem ? "red" : undefined,
        }}
      >
        {message}
      </div>
    </div>
  );
};

export default MessageComponent;
