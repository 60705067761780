import * as React from "react";
import styled from "styled-components";

import { Message } from "../../../../interfaces";
import MessageComponent from "./Message";
import { useAppSelector } from "../../../../hooks";

interface IMessageProps {
  settings?: {
    autoScroll?: boolean;
  };
}

const MessagesWrapper = styled.div`
  flex: 1 1 auto;
  transition: width 0.5s;
  // height: 75vh;
  overflow-y: scroll;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.2);
  flex-grow: 1;
`;

const Messages: React.FC<IMessageProps> = ({ settings }) => {
  const messageEl = React.useRef<HTMLDivElement>(null);

  const messages = useAppSelector((state) => state.messageSlice.messages);

  React.useEffect(() => {
    if (settings?.autoScroll !== false) {
      if (messageEl) {
        messageEl.current!.scroll({
          top: messageEl.current!.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [messages, settings?.autoScroll]);

  const renderMessages = (messages: Message[]) =>
    messages.map(({ message, user }, index) => {
      // TODO don't use index for key - message guid or something
      return (
        <MessageComponent
          message={message}
          user={user}
          key={index}
          // newUserMessage={isnewUserMessage}
        />
      );
    });

  return (
    <MessagesWrapper
      ref={messageEl}
      // style={{
      //   transition: "width 0.5s",
      //   // height: "75vh",
      //   overflowY: "scroll",
      //   padding: "2rem",
      //   backgroundColor: "rgba(0,0,0,0.2)",
      //   flexGrow: 1,
      // }}
    >
      {renderMessages(messages)}
    </MessagesWrapper>
  );
};

export default Messages;
