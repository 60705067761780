import axios, { AxiosError } from "axios";
import configuration from "../../configuration";

const authenticate = async (password: string): Promise<string> => {
  const { API_ENDPOINT } = configuration;

  const url = `${API_ENDPOINT}auth`;

  const response = await axios
    .post(url, { password })
    .catch((e: AxiosError<{ error?: string }>) => {
      if (axios.isAxiosError(e)) {
        const error = e.response?.data?.error ?? "No error provided";

        throw new Error(error);
      }

      throw new Error("Unknown error authenticating");
    });

  const { data } = response;

  if (!data?.token) {
    throw new Error("No token in response");
  }

  return data.token;
};

export default authenticate;
