import * as React from "react";

// TODO styled-components or something nicer than manual style obj

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

const Button: React.FC<IButtonProps> = (props) => {
  // Todo pass props through corrlectly...
  return (
    <button
      {...props}
      style={{
        cursor: "pointer",
        height: "2.5rem",
        minWidth: "10rem",
        marginLeft: "auto",
        backgroundColor: "rgba(255,255,255,0.25)",
        color: "rgba(255,255,255,0.8)",
        borderColor: "transparent",
        ...props.style,
      }}
    />
  );
};

export default Button;
