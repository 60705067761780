import React from "react";
import ReactDOM from "react-dom/client";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";
import Admin from "./Pages/Admin";
import AppWrapper from "./Components/AppWrapper";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="/"
        element={
          <Provider store={store}>
            <App />
          </Provider>
        }
      ></Route>
      <Route path="/admin" element={<Admin />} />
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <div className="App">
      <header className="App-header">
        <AppWrapper>
          <RouterProvider router={router} />
        </AppWrapper>
      </header>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
