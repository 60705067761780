import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../interfaces";

interface PlayersState {
  players: User[];
}

const initialState: PlayersState = {
  players: [],
};

export const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    addPlayer: (state, action: PayloadAction<User>) => {
      // Find existing player

      const existingPlayer = state.players.findIndex((player) => {
        return player.id === action.payload.id;
      });

      if (existingPlayer !== -1) {
        state.players[existingPlayer] = action.payload;
        return;
      }
      state.players.push(action.payload);
    },
    removePlayer: (state, action: PayloadAction<string>) => {
      state.players = state.players.filter((player) => {
        return player.id !== action.payload;
      });
    },
    setPlayers: (state, action: PayloadAction<User[]>) => {
      state.players = action.payload || [];
    },
    clearplayers: (state) => {
      state.players = [];
    },
  },
});

export const { addPlayer, removePlayer, clearplayers, setPlayers } =
  playersSlice.actions;

export const selectplayers = (state: { players: PlayersState }) => {
  return state.players;
};

export default playersSlice.reducer;
