import * as React from "react";
import authenticate from "../../../api/authenticate";
import Text from "../../../Components/Basic/Text";

interface AuthenticateProps {
  authFn: (token: string) => void;
}

const Authenticate: React.FC<AuthenticateProps> = ({ authFn }) => {
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const submit = async () => {
    try {
      setLoading(true);
      const token = await authenticate(password);

      authFn(token);
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    (async () => {
      console.log("Key press?", event.key);
      if (event.key === "Enter") {
        if (password.length > 0) {
          await submit();
        }
      }
    })();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Input */}
      <div>
        <Text
          type="password"
          value={password}
          placeholder="Password"
          onKeyDown={handleKeyPress}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {/* Error */}
      {error && <div>{error}</div>}
    </div>
  );
};

export default Authenticate;
