import * as React from "react";
import { debounce } from "lodash";
import styled from "styled-components";

import sendSocketMessage from "../../../../socket/sendSocketMessage";
import Button from "../../../../Components/Basic/Button";
import Text from "../../../../Components/Basic/Text";
import Toggle from "../../../../Components/Basic/Toggle";

import * as constants from "../../../../constants";

const { mobileWidth } = constants.default.breakPoints;

const InputContainer = styled.div`
  /* height: 75px; */
  flex: 0 1 2rem;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  @media (max-width: ${mobileWidth}px) {
    flex-direction: column;
  }
`;

const TextContainer = styled(Text)`
  width: 80%;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  font-family: inherit;
`;

const TextSettingsContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${mobileWidth}px) {
    margin-bottom: 2rem;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: ${mobileWidth}px) {
    width: 90%;
    margin-right: auto;
  }
`;

interface IInputProps {
  autoScroll: boolean;
  toggleAutoScroll: () => void;
}

const Input: React.FC<IInputProps> = ({ autoScroll, toggleAutoScroll }) => {
  const [message, setMessage] = React.useState<string>("");

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      debouncedSendMessage();
    }
  };
  const sendMessage = () => {
    (async () => {
      if (message?.length > 0) {
        sendSocketMessage({
          type: "message",
          data: message,
        });
      }
    })();
    setMessage("");
  };

  const debouncedSendMessage = debounce(sendMessage, 500, { leading: true });

  return (
    <InputContainer>
      {/* Div with a border */}
      <TextSettingsContainer>
        <TextContainer
          type="text"
          onKeyDown={handleKeyPress}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          placeholder="Enter a message"
          style={{}}
        />
        <Toggle text={"Scroll"} toggle={toggleAutoScroll} value={autoScroll} />
      </TextSettingsContainer>

      <StyledButton onClick={debouncedSendMessage}>Send</StyledButton>
    </InputContainer>
  );
};

export default Input;
