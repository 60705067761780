interface LocalStorage {
  username: string | null;
  token: string | null;
  colour: string | null;
}

export const getLocalStorage = (key: keyof LocalStorage): string | null => {
  return window.localStorage.getItem(key) ?? null;
};

export const setLocalStorage = (key: string, value: string | null) => {
  return window.localStorage.setItem(key, value ?? "");
};

export const removeLocalStorage = (key: string) => {
  return window.localStorage.removeItem(key);
};
